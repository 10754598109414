import React from 'react'

function CardLinkedin (props){   
        return(
            <div className="cardLinkedin col-md-3 col-10 mx-5 col-sm-6 mt-5  ">
                <div className='bg-white rounded text-center  p-4 '>                        
                    <h5>{props.name}</h5>
                    <img alt="foto-perfil" className='w-50 mt-2 rounded-circle' src={props.photo}></img>
                    <p className='mt-3'>{props.desc}</p>
                    <small>Del instituto IT-Arg</small>
                    <br/>
                    <a target='_blank' rel="noreferrer" href={props.link}><button className="btn  btn-outline-primary w-50 mt-3">Ver Perfil</button></a>
                </div>
            </div>
        )
}
export default CardLinkedin