import {useParams} from 'react-router-dom'
import Banner from '../components/Banner';
import Banner2 from '../components/Banner2';
import ModulesList from '../components/ModulesList';
import React, { useEffect } from 'react';
import "./styles/course.css"


function Course() {

    let courseObj;
    const params = useParams(); 
    //llamo server con course ej Hardware
    if(params.course === "Hardware"){
        courseObj = {
            banner: "../img/Banner-Hardware.png",
            banner2: "../img/Titulo-ArmadoComputadoras.png",
                modules: [
                "../img/Modulo-Hardware1.png",
                "../img/Modulo-Hardware2.png",
                "../img/Modulo-Hardware3.png",
                "../img/Modulo-Hardware4.png"
            ],
            classes: "32 clases (1 por semana)"       
        }
    }else if(params.course === "Seguridad"){
        courseObj = {
            banner: "../img/Banner-Seguridad.png",
            banner2: "../img/Titulo-SeguridadInformatica.png",
                modules: [
                "../img/Modulo-Seguridad1.png",
                "../img/Modulo-Seguridad2.png"
            ],
            classes: "56 clases (1 por semana)"       
        }
    }else if(params.course === "Redes"){
        courseObj = {
            banner: "../img/Banner-Redes.png",
            banner2: "../img/Titulo-Redes.png",
                modules: [
                "../img/Modulo-Redes1.png",
                "../img/Modulo-Redes2.png",
                "../img/Modulo-Redes3.png"
            ],
            classes: "56 clases (1 por semana)"       
        }
    }else if(params.course === "Linux"){
        courseObj = {
            banner: "../img/Header-Linux.jpg",
            banner2: "../img/TituloLinux.png",
                modules: [
                "../img/TextoLinux1.png",
                "../img/TextoLinux2.png",
                "../img/TextoLinux3.png",
                "../img/TextoLinux4.png",
                "../img/TextoLinux5.png"
            ],
            classes: "20 clases (1 por semana)"       
        }        
    }
    

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return(
        <>
            <main className='container'>
                <Banner photo={courseObj.banner}/>

                <Banner2 photo={courseObj.banner2}/>
                <ModulesList arrayModules={courseObj.modules}/>
                <h2 className='courseClasses-H3 text-center mt-md-5 mt-2'>{courseObj.classes}</h2>
            </main>
        </>
    )   
}
export default Course;