import React from "react";
import Circuit1 from "../components/Circuit1";
import CoursesList from '../components/CoursesList';
import { Outlet } from "react-router-dom";
import Banner from "../components/Banner";


function Courses (){
    return(
        <div>
            <Outlet/>
            <main className="container">
                <Banner photo="./img/Portada-Home.png"/>
                <Circuit1/>
                <CoursesList/>
            </main>            

        </div>
    )   

}
export default Courses