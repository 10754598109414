import React from 'react'

function Banner2(props){   
        return(
            <div>
                <div className="justify-content-center row">
                    <div className="col-auto text-center">
                        <img className="mt-5 pt-md-5" width="70%" src={props.photo} alt="portada 2"/>
                    </div>
                </div>
            </div>
        )
}
export default Banner2