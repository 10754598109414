import React from "react";
import "../components/styles/courses.css"
import { NavLink } from "react-router-dom";

function CoursesList() {
        return(
            <section id="cursos">
                 <div className="bg-IT-Pattern p-5">
                    <div className="mt-3  row courses-div">

                        <div className="col-12 col-md-6 mt-5">
                            <NavLink to="/curso/Hardware"><img className="p-md-4 w-100" src="../img/Btn-Reparacion.png" alt="curso armado y reparacion"/></NavLink >
                        </div> 

                        <div className="col-12 col-md-6 mt-5">
                            <NavLink to="/curso/Redes"><img className="p-md-4 w-100" src="../img/Btn-Redes.png" alt="Administracion de redes informaticas"/></NavLink >
                        </div>

                        <div className="col-12 col-md-6 mt-5">
                            <NavLink to="/curso/Seguridad"><img className="p-md-4 w-100" src="../img/Btn-Seguridad-Informatica.png" alt="especializacion en seguridad informatica"/></NavLink >
                        </div>                        

                        <div className="col-12 col-md-6 mt-5">
                            <NavLink to="/curso/Linux"><img className="p-md-4 w-100" src="../img/Btn-Linux.png" alt="Administracion linux"/></NavLink >
                        </div>                   
                    </div>                    
                    
                </div>
            </section>
        )    
}
export default CoursesList