import React from "react";
import Circuit1 from "../components/Circuit1";

function NoPage(){
    return(
        <main className="container">
            <Circuit1/>
            <h1 className="text-center text-white mt-5 mb-5">Ups! Error 404<br></br>Pagina no encontrada </h1>
        </main>
    )
}
export default NoPage