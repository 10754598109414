import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import Vision from './pages/Vision';
import Modality from './pages/Modality';
import Contact from './pages/Contact';
import Course from './pages/Course';
import Courses from './pages/Courses';
import Certificates from './pages/Certificates';
import Certificate from './pages/Certificate';
import CertificateAll from './pages/CertificateAll';
const root = createRoot(document.getElementById('root')); 

root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/'   element={<App />}>
                <Route index element={<Home />} />
                <Route exact path="vision" element={<Vision />} />
                <Route exact path="cursos" element={<Courses />}/>                    
                <Route exact path="/curso/:course" element={<Course />} />                
                <Route exact path="modalidad" element={<Modality />} />
                <Route exact path="nosotros" element={<Contact />} />    

                <Route exact path="certificados" element={<Certificates />} />
                <Route exact path="certificadosEstudiante/:code" element={<CertificateAll />} />

                <Route exact path="certificados/:error" element={<Certificates />} /> 

                <Route exact path="certificado/:code" element={<Certificate />} /> 
 
                <Route exact path="*" element={<NoPage />} />
            </Route>
        </Routes>
    </BrowserRouter>
);