import {React} from "react";
import {useParams} from 'react-router-dom'
import useCallApi from "../hooks/useCallApi";

function Certificate(){
    
    const params = useParams()
    const { getCall } = useCallApi();
    const BASE_URL = process.env.REACT_APP_API_URL;

    
    const linkedin = async () => {
        try {
            // Fetch certificate data using the certificate code from params
            const data = await getCall("Certificate/" + params.code);
            console.log(params.code)
            // Create a new Date object from the certificate date and extract the month and year
            let date = new Date(data.dateCertificate);
            let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure the month has two digits (e.g., '01' for January)
            let year = date.getFullYear();
    
            // Encode the course name and certificate URL to ensure special characters are handled properly
            const courseNameEncoded = encodeURIComponent(data.courseName);
            const certUrlEncoded = encodeURIComponent("https://it-arg.com/certificado/" + data.code);
    
            // Open LinkedIn URL with properly encoded parameters in a new tab
            window.open(
                `https://www.linkedin.com/profile/add?${courseNameEncoded}&name=${courseNameEncoded}&organizationId=81418679&issueYear=${year}&issueMonth=${month}&certUrl=${certUrlEncoded}&certId=${data.code}`, 
                '_blank'
            );
        } catch (error) {
            // Log any errors that occur during the data fetching or URL opening
            console.error('Error opening LinkedIn URL:', error);
        }
    };

    const cursorCol ={
        cursor: "pointer"
    };

    return(
        <main className="container text-center">
            <img className="w-75 mt-5" src={BASE_URL + "imgCertificates/" + params.code + ".jpg"} alt="certificado"></img>
            <div className="row justify-content-evenly mt-5 ">
                <div className="col-md-2 col-5">
                    <a href={BASE_URL + "Certificate/downloadCertificate/" + params.code} style={cursorCol} download className="text-white text-decoration-none">
                        <img style={cursorCol} className="w-25 " src="../img/download.png" />
                        <br/>
                        Descargar
                    </a>
                </div>
                <div className="col-md-2 col-5 cursor-col">
                    <img style={cursorCol} onClick={linkedin} alt="linkedin" className="w-25" src="../img/logo-linkedin-icon.png" />
                    <br/> <p style={cursorCol} onClick={linkedin} className="text-white" >Licencias y certificados</p>
                </div>                
            </div>
        </main>
    )
}
export default Certificate;