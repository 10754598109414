import React from "react";
import "../components/styles/courses.css"
import "./styles/CardSocialMedia.css"

function SocialMediaList() {
        return(
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-3 col-10 mx-5 col-sm-6  mt-5">
                        <div>
                            <img className="rounded-top socialMediaFoto" src="./img/LinkedinBanner.png" alt="Linkedin"/>
                        </div>    
                        <div className='bg-white text-black text-start  px-2 pt-2 pb-2 '>
                            <a target='_blank' rel="noreferrer" href="https://www.linkedin.com/company/instituto-it-arg/"><button className="rounded-pill btn btn-outline-primary w-50 mt-3"><small>Seguir Pagina </small></button></a>
                            <br/><br/>
                            <small>IT-Arg: <small className='text-primary'>Comunidad Educativa</small></small>
                            <br/>
                            <small>Sitio Web: <a href="it-arg.com" className='text-primary'><small>it-arg.com</small></a></small>
                            <br/>
                            <small>Sector: <small className='text-primary'>Educacion</small></small>
                            <br/>
                            <small>Formando <small className='text-primary'>Técnicos y Docentes</small></small>
                            <br/>
                            <small>Telefono:<small className='text-primary'> +54-9-11-7895-6977</small></small>
                            <hr/>
                            <small>Las ventajas del <small className='text-primary'>#AprendizajeOnline</small> con las virtudes del <small className='text-primary'>#AulaPresencial</small></small>
                        </div>  
                        <div className="text-center bg-white pb-3">
                            <a target='_blank' rel="noreferrer" href="https://www.linkedin.com/company/instituto-it-arg/"><button className="btn  btn-outline-primary w-75 mt-3">Siguenos en linkedin</button></a>
                        </div>               
                        <br/>
                    </div>
                
                    <div className="col-md-3 p-0 facebookBackground col-10 col-sm-6 mx-5 mt-5">
                        <div className="fb-page" data-href="https://www.facebook.com/Instituto.IT.arg/" data-tabs="timeline" data-width="380" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Instituto.IT.arg/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Instituto.IT.arg/"> </a></blockquote></div>
                    </div>

                    <div className="col-md-3 col-10 col-sm-6 mt-5 mx-5">
                        <a target='_blank' rel="noreferrer" href="https://www.instagram.com/instituto.it.arg/">
                            <div>
                                <img className="rounded-top socialMediaFoto" src="./img/instagram-banner.png" alt="Linkedin"/>
                            </div>    
                            <div className='bg-white text-black text-start  px-2 pt-2 pb-2 '>
                                <img alt="instagram feed" className="w-100" src="./img/instafeed.png"/>
                            </div>  
                            <div className="text-center bg-white pb-3">
                               <button className="btn  btn-outline-primary w-75 mt-3">Siguenos en Instagram</button>
                            </div>               
                            <br/>
                        </a>
                    </div>

                </div>
            </div>
          
        )    
}
export default SocialMediaList