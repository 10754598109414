import React from "react";
import Banner from '../components/Banner'
import Circuit2 from "../components/Circuit2";
import Banner2 from '../components/Banner2';
import TitleParagraph from "../components/TitleParagraph";


function Modality(){
    
    return(
        <div>

            <main>
                <div className="container mb-3">
                    <Banner photo="./img/Portada-Modalidad.jpg"/>       

                    <Banner2 photo="./img/portada2.png"/>


                    <section className="mt-5 mb-5">
                        <div className="text-center">
                            <img className="pt-md-5 img-title" alt="titulo"  src="./img/Modalidad-aprendizaje.png" />
                        </div>

                        <div className="row mt-5 justify-content-center">
                            <div className="text-center divTextPostTitle col-md-9 col-9 ">
                                <p>Clases Online - Presenciales- Sincrónicas - Grupales - Interactivas</p>
                            </div>
                        </div>            
                    </section>

                    <Circuit2/>


                    <TitleParagraph photo="./img/Entrevista-Inicial.png" text="El primer paso para el ingreso a la comunidad
                                                    de IT-arg está dado por una entrevista
                                                    personalizada con la dirección del Instituto a la
                                                    que cada aspirante accede, durante la misma se
                                                    lo interioriza sobre la filosofía, los objetivos,
                                                    encuadre y forma de trabajo institucional. El
                                                    interesado plantea sus objetivos, evacúa sus
                                                    dudas y en base a la propuesta se le presenta la
                                                    oferta de cursos disponibles acorde a su nivel e

                                                    intereses." />

                    <Circuit2/>

                    <TitleParagraph photo="./img/Planes-Estudio.png" text="Cada plan de estudios ha sido desarrollado por
                                                    IT-arg con el objetivo de capacitar a los alumnos
                                                    en el desarrollo de roles y/o tareas especificas.
                                                    Para ello se evalúan las habilidades requeridas,
                                                    los conocimientos previos por parte del alumno
                                                    que serán necesarios para acceder a dicha
                                                    capacitación. Luego se les listan las “Unidades
                                                    Temáticas”, se ponderan y ordenan de acuerdo a
                                                    varios criterios, para finalmente incluirlas en la
                                                    curricular. \n
                                                    Los Planes de Estudio son construidos previendo las particularidades de cada grupo, de
                                                    esta forma los docentes cuentan con la
                                                    flexibilidad suficiente que les permitirá adecuar
                                                    la profundidad con la que cada “Unidad
                                                    Temática” será abordada de acuerdo a las
                                                    particularidades de cada grupo." />

                    <Circuit2/>
                    <TitleParagraph photo="./img/Dinamica.png" text="El alumno estudia el material didáctico previo a
                                                    la clase. \n

                                                    En la clase se realiza el abordaje de la unidad
                                                    temática especifica mediante diversas técnicas
                                                    pedagógicas y de dinámica grupal.\n

                                                    Al finalizar los alumnos cuentan con los
                                                    materiales (Videos, Guías, Herramientas, etc.)
                                                    que le permiten replicar las “Demostraciones
                                                    Didácticas” y “Prácticas Programas”." />
                    <Circuit2/>
                    <TitleParagraph photo="./img/Las-Clases.png" text="Todas nuestras clases son por la plataforma
                                                    ZOOM en vivo y participativas, no son clases
                                                    grabadas y se requiere tener la aplicación,
                                                    conexión a internet y la cámara prendida
                                                    durante todo el proceso. La frecuencia es una
                                                    clase semanal con promedio de hora y media." />

                    <Circuit2/>
                    <TitleParagraph photo="./img/Evaluacion.png" text="En IT-arg la evaluación es parte del proceso de aprendizaje. 
                                                    La conformación de grupos pequeños y el seguiento clase a clase de cada alumno, grupo y docente
                                                    nos permiten el diagnostico temprano y la adecuación de técnicas y estrategias a cada situación." />
                    <Circuit2/>
                    <TitleParagraph photo="./img/Certificacion.png" text="Cada alumno recibe su certificado al finalizar la cursada y/o módulo tras cumplir con los requerimientos específicos.
                                                    Los Certificados son enviado en formato digital y quedan publicados en nuestra Web para que los mismos puedan ser validados. A su vez contamos 
                                                    con un mecanismo automatizado para que todas las ertificaciones emitidas por IT-arg puedan ser vinculadas a LinkedIn" />

                </div>
            </main>
              

         

           
        </div>
        
    )   

}
export default Modality