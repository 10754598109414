import React from "react";
import "./styles/TitleParagraph.css"

function TitleParagraph(props) {

    let divTitleImg;
    if(props.photo !== ""){
        divTitleImg = 
            <div className="text-center">
                <img className="pt-md-4 img-title" alt="titulo"  src={props.photo} />
            </div>
    }
    return(
        <section className="mt-5 mb-5 pb-md-4">

            {divTitleImg}                    
            
            <div className="row mt-5 justify-content-center">
                <div className="divTextPostTitle col-md-9 col-9 ">
                    {props.text.split("\\n").map((line, index) => (
                        <React.Fragment key={index}>
                            <p>{line}</p>
                        </React.Fragment>
                    ))}

                </div>
            </div>            
        </section>
    )   
}
export default TitleParagraph