import React from "react";
import Banner from '../components/Banner'
import Circuit2 from '../components/Circuit2'
import TitleParagraph from "../components/TitleParagraph";
import Circuit1 from "../components/Circuit1";
import "./styles/vision.css";

function Vision(){
    
    return(
        <div>
           
           <main className="container mb-3">
                <Banner photo="./img/Portada-Vision.jpg"/>

                <TitleParagraph photo="./img/Titulo-1.png" text="La Tecnología Informática (IT), según lo definido por la
                            Asociación de la Tecnología Informática de América (ITAA),
                            es: el estudio, diseño, desarrollo, innovación puesta en práctica,
                            ayuda o gerencia de los sistemas informáticos computarizados,
                            particularmente en los usos del software." />
                <Circuit1/>


                <TitleParagraph photo="./img/Titulo-2.png" text="Porque es nuestra forma de vida, ver, mirar; oír, escuchar, día
                        a día, en el devenir, conscientes del contexto, agradeciendo cada
                        pequeño logro, aprendiendo de cada error, aceptando la incertidumbre
                        y tratando de 'esquivar la zanahoria' siempre que la podamos
                        reconocer y tengamos la valentía de afrontar las consecuencias. \n
                        Nos reconocemos como seres sociales y, como tales, el mayor logro
                        es poder compartir entre nosotros, creciendo, siendo mejores
                        personas y profesionales, cada día disfrutando del proceso,
                        aprendiendo juntos, estudiando, y adquirir así conocimiento, ese
                        bien intangible, tan apreciado como inagotable. \n
                        Crecer, crear, siempre con algo nuevo para compartir:
                        'Creciendo, y compartiendo'. Así vamos creando un círculo virtuoso,
                        una 'espiral' de aprendizaje hacia la próxima vuelta,
                        conscientes de cada paso dado." />
                <Circuit2/>
                <div className="bg-IT-Pattern2">
                    <TitleParagraph photo="./img/Titulo-3.png" text="Tratando de vivir de acuerdo a nuestra visión e intentando tomar las
                                mejores decisiones para evitar alejarnos de ese 'no objetivo'. Vivir
                                haciendo lo que uno ama, en nuestro caso la pedagogía y la
                                tecnología combinadas, con pasión, para que eso no se transforme en
                                'trabajo asalariado' o mera transacción mercantil, sino el sustento
                                necesario para nuestra vida en el mundo." />
                </div>

            </main>

        </div>
    )
}
export default Vision