import { React, useState, useEffect } from "react";
import Circuit1 from "../components/Circuit1";
import SearchCertificates from "../components/SearchCertificates";
import { useParams } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import Banner from "../components/Banner";
import { useRef } from "react";


function Certificates() {
    const params = useParams();
    const [show, setShow] = useState(false);
    const targetRef = useRef(null);


    useEffect(() => {
        if (params.error === "error") {
            setShow(true)
            if (targetRef.current) {
                window.scrollTo({
                    top: targetRef.current.offsetTop
                });
            }
        }
    }, [params.error])

    return (
        <main className="container">
            <Banner photo="../img/Banner-Certificados.jpg" />
            <div ref={targetRef}>
                <Circuit1 />
                <Alert show={show} variant="danger" onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>Codigo de certificado o alumno invalido</Alert.Heading>
                    <p>
                        No hemos podido encontrar el codigo del certificado o del alumno que esta buscando.
                    </p>
                </Alert>
                <SearchCertificates />
            </div>
        </main>
    )

}
export default Certificates