import React from "react";
import "./styles/ModuleCourse.css";

function ModuleCourse(props) {   
    return(
        <div className="col-md-6 col-12 mt-5 mb-5 text-center" >
            <img className="moduleCourse-img " src={props.photo} alt={props.photo}></img>
        </div>               
    )   
}
export default ModuleCourse