import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const BASE_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' }
  });

const useCallApi = () => {
      
    //This funcion call the api and return the data
    const getCall = async (url) => {
        const response = await apiClient.get(url)
        .then(response => {
            return response

        }).catch(error => {
            const swalDelete = withReactContent(Swal)
            swalDelete.fire({
                icon: 'error',
                title: error.response.data,
                confirmButtonText: "Aceptar"
            })
        });
        return response.data
    }
    return {getCall};
}

export default useCallApi;