import React from "react";
import Banner from '../components/Banner'
import Banner2 from '../components/Banner2';
import Circuit1 from "../components/Circuit1";
import Circuit2 from "../components/Circuit2";
import SocialMediaList from "../components/SocialMediaList";


function Home(){
    return(
        <div>
            <main className="container">
                
                <Banner photo="./img/Portada-Home.jpg"/>

                <Banner2 photo="./img/portada2.png"/>
                <p className="text-white fs-4 mt-5">Nuestros encuentros son exclusivamente online. En nuestra área de conocimiento, lo remoto, es ahora cercanía: la tecnología nos devuelve la oralidad, y enriquece el texto escrito; aprendemos juntos y nos vemos y reconocemos como sujetos de aprendizaje y como aprendices de uso de la tecnología: nuestra exploración es a la vez, medio y mensaje.</p>

                <Circuit1/>
                
                <div className="text-center mt-5 mb-5">
                    <img className="pt-md-4 img-title" alt="titulo"  src="./img/Nuestros-Valores.png" />
                    <p className="fs-4 mt-5 text-white">Pasión - Dedicación - Constancia - Esfuerzo - Paciencia</p>
                </div>

                <Circuit2/>

                
                <SocialMediaList/>
                
            </main>            

        </div>
    )   
}
export default Home