import { React, useEffect, useState, Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "../API/Axios";
import { Alert } from 'react-bootstrap'

function CertificateAll() {

    const navigate = useNavigate();
    const params = useParams();
    const [codesList, setcodeList] = useState("");
    const [show, setShow] = useState(false);
    const BASE_URL = process.env.REACT_APP_API_URL;


    useEffect(() => {
        axios.get("certificate/certificatesGetCode/" + params.code)
            .then(response => {

                if (Object.keys(response.data).length !== 0)
                    setcodeList(response.data);
                else {
                    setShow(true)
                }
            })
            .catch(error => {
                navigate("/certificados/error");
            });
    }, []);

    return (
        <main className="container text-center">
            <h2 className="mt-5 text-white">Certificados - {params.code}</h2>
            <div className="row mt-5 ">
                <Alert show={show} variant="primary" onClose={() => setShow(false)}>
                    <Alert.Heading>El Alumno no tiene certificados</Alert.Heading>
                </Alert>

                {codesList !== "" &&
                    codesList.map(data => (
                        <Fragment  key={data.code}>
                            <div className="col-md-4">
                                <a href={"/certificado/" + data.code}>
                                    <img className="w-100 mt-5" src={BASE_URL + "imgCertificates/" + data.code + ".jpg"} alt="certificado" />
                                </a>
                            </div>
                            {/* Conditional if the student has a mention in the previous certificate*/}
                            {data.isMention ? (
                                <div className="col-md-4">
                                    <a href={"/certificado/" + data.code + "m"}>
                                        <img className="w-100 mt-5" src={BASE_URL + "imgCertificates/" + data.code + "m.jpg"} alt="certificado" />
                                    </a>
                                </div>
                            ) : null}
                        </Fragment>
                    ))
                }
            </div>
        </main>
    )
}
export default CertificateAll;