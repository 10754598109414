import React,{ useEffect } from "react";
import ModuleCourse from "./ModuleCourse";

function ModulesList(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        
        <section className="mt-5">
            <div className="row justify-content-center">
                {props.arrayModules.map((module,index)=>
                    <ModuleCourse key={index} photo={module}/>
                )}               
            </div>
        </section>          
               
    )   
}
export default ModulesList