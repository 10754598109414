import React from "react";
import Banner from '../components/Banner'
import Circuit1 from "../components/Circuit1";
import CardLinkedin from "../components/CardLinkedin";
import TitlePage from "../components/TitlePage";

function Contact(){
    
    return(
        <div>
            <main className="container mb-3">
                <Banner photo="./img/Portada-Vision.png"/>
                <Circuit1/>
                <TitlePage title="Nuestro equipo"/>
                <div className="row justify-content-center">
                    <CardLinkedin name="Nicolas Margossian" desc="Webmaster" link="https://www.linkedin.com/in/nicolas-margossian-9a5145188/" photo="../img/nm.jfif"/>
                    <CardLinkedin name="Rodrigo P. Balsells" desc="Director" link="https://www.linkedin.com/in/rodrigo-p-balsells-41a87057/" photo="../img/rb.jfif"/>
                    <CardLinkedin name="Matias Balancini" desc="Profesor" link="https://www.linkedin.com/in/matias-balancini/" photo="../img/matias.jfif"/>
                    <CardLinkedin name="Monica Heredia"  desc="Administradora" link="https://www.linkedin.com/in/monica-lopez-de-heredia-84bab122b/" photo="../img/mh.jfif"/>
                    <CardLinkedin name="Alirio García" desc="Diseñador Grafico y CM" link="https://www.linkedin.com/in/aliriogarciadg/" photo="../img/alirio.jfif"/>
                    <CardLinkedin name="Joaquin Santamaria" desc="Profesor" link="https://www.linkedin.com/in/joaquin-santamaria-12622854/" photo="../img/joaquin.jfif"/>
                </div>
            </main>
        </div>
        
    )   

}
export default Contact