import React from 'react'
import { Outlet, NavLink } from "react-router-dom";
import Circuit2 from './Circuit2';
import "./styles/App.css"
import { useEffect, useState } from "react";

function App(){
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
        if (window.pageYOffset > 700) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
        });
    }, []);

  // This function will scroll the window to the top 
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
        });
    };
    return(
        <>   
        <a href="https://api.whatsapp.com/send?
         phone=541178956977&text=Hola, me comunico desde la pagina Web!"
   		 className="floatWPP" target="_blank" rel="noreferrer">
            <img src="../img/wpp-logo.svg" alt="img"/>
        </a>
        {showButton && (
                    <button onClick={scrollToTop} className="back-to-top" >
                        <img className='w-100' src='../img/img-to-top.png' alt='scroll img'/>
                    </button>
                )}
        
        <header className="container">
            <div className="row mt-3">
                <div className="mt-5 offset-md-1 col-md-2 d-none d-md-block">
                    <img width="120%" src="../img/Information-Technology-Comunidad-Educativa.png" alt="img"/>
                </div>
                <div className="offset-md-1 text-center col-md-4">
                    <img className='logo' src="../img/Logo.png" alt="logo"/>
                </div>
                <div className="mt-5 offset-md-1 col-md-2 d-none d-md-block">
                    <img width="120%" src="../img/WhatsApp.png" alt="numero whatsapp"/>
                </div>
            </div>        
        </header>
        <div className='container'>
            <nav className="fs-6 navbar sticky-top navbar-expand-lg navbar-dark bg-dark  mt-4">
                <div className="container-fluid">           

                    <NavLink reloadDocument className="fs-6 navbar-brand" to="/">Inicio</NavLink >
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">                            
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/cursos">Cursos</NavLink >
                            </li>

                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/vision">Vision</NavLink >
                            </li>     
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/modalidad">Modalidad</NavLink >
                            </li> 
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/nosotros">Nosotros</NavLink >
                            </li>   
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/certificados">Certificados</NavLink >
                            </li>          
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        
        

        <Outlet />
        
        <footer className="container">
            <Circuit2/>
            <div className="text-center">
                
                <a rel="noreferrer" href="https://api.whatsapp.com/send?phone=541178956977" target="_blank"><img className="wppFooter mb-md-5" src="../img/Btn-WhatsApp.png" alt="img whatsapp"/></a>

                <div className="mt-5 row ">                
                    <div className='offset-md-3 col-md-1 col-2'><a rel="noreferrer" target="_blank" href="https://t.me/soporterpb"><img className='socialIcon'  src="../img/Btn-Telegram.png" alt="Telegram"/></a></div>
                    <div className='col-md-1 col-2'><a rel="noreferrer" target="_blank" href="https://www.facebook.com/Instituto.IT.arg/"><img className='socialIcon' src="../img/Btn-Facebook.png" alt="Facebook"/></a></div>
                    <div className='col-md-1 col-2'><a rel="noreferrer" target="_blank" href="https://www.instagram.com/instituto.it.arg/"><img className='socialIcon' src="../img/Btn-Instagram.png" alt="Instagram"/></a></div>
                    <div className='col-md-1 col-2'><a rel="noreferrer" target="_blank" href="https://ar.linkedin.com/company/instituto-it-arg"><img className='socialIcon' src="../img/Btn-Linkedin.png" alt="Linkedin"/></a></div>
                    <div className='col-md-1 col-2'><a rel="noreferrer" target="_blank" href="https://www.youtube.com/c/RodrigoPabloBalsells"><img className='socialIcon' src="../img/Btn-YouTube.png" alt="Youtube"/></a></div>
                    <div className='col-md-1 col-2'><a rel="noreferrer" target="_blank" href="https://discord.gg/D8zvEcQudX"><img className='socialIcon' src="../img/Btn-Discord.png" alt="Discord"/></a></div>
                </div>

            </div>
        
            <div className="text-center mt-3">
                <img src="../img/CircuitoFooter.png" className="w-75" alt="circuito3"/>
            </div>
        
            <div className="text-center mb-5">
               <a href='mailto:info@it-arg.com'> <img className="mt-5 email-footer" src="../img/E-mail.png" alt="email"/> </a>
            </div>
        </footer>
    </>
    )   
}
export default App
